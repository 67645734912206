// File: /src/components/Home.js

import React, { lazy, Suspense } from "react";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import HomeIntro from "./HomeIntro";
import OfficeStatus from "./OfficeStatus"; // Importing the new OfficeStatus component
import { Helmet } from "react-helmet";

// Lazy-loaded components
const LazyMissionStatement = lazy(() => import("../About/MissionStatement"));
const LazyOfficeInfo = lazy(() => import("../About/OfficeInfo"));
const LazyOwnerInfo = lazy(() => import("../About/OwnerInfo"));
const LazyServices = lazy(() => import("../Services/Services"));
const LazyPricingDetails = lazy(() => import("../Pricing/PricingDetails"));
const LazyContact = lazy(() => import("../Contact/Contact"));
const LazyMore = lazy(() => import("../Resources/Resources"));
const LazyFAQ = lazy(() => import("../FAQ/FAQ"));

const AnimatedComponent = ({ children }) => {
    return (
        <InView triggerOnce>
            {({ inView, ref }) => (
                <motion.div
                    ref={ref}
                    initial={{ opacity: 0, y: 50 }}
                    animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 150 }}
                    transition={{ duration: 0.75 }}
                >
                    {children}
                </motion.div>
            )}
        </InView>
    );
};

const Home = () => {
    return (
        <div className="home-container">
            <Helmet>
                <link rel="preload" as="script" href="./ChaosAnimation.js" />
            </Helmet>
            {/* Display Office Status Notification */}
            <AnimatedComponent>
                <OfficeStatus />
            </AnimatedComponent>

            <HomeIntro />

            <Suspense fallback={<div>Loading...</div>}>
                <AnimatedComponent>
                    <LazyMissionStatement />
                </AnimatedComponent>

                <AnimatedComponent>
                    <LazyServices />
                </AnimatedComponent>
                <AnimatedComponent>
                    <LazyOfficeInfo />
                </AnimatedComponent>
                <AnimatedComponent>
                    <LazyOwnerInfo />
                </AnimatedComponent>
                <AnimatedComponent>
                    <LazyPricingDetails />
                </AnimatedComponent>
                <AnimatedComponent>
                    <LazyContact />
                </AnimatedComponent>
                <AnimatedComponent>
                    <LazyFAQ />
                </AnimatedComponent>
                <AnimatedComponent>
                    <LazyMore />
                </AnimatedComponent>
            </Suspense>
        </div>
    );
};

export default Home;
