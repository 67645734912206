// File: /src/components/OfficeStatus.js

import React, { useState, useEffect } from "react";
import "./OfficeStatus.scss"; // Import styles

const OfficeStatus = () => {
    // Office closure settings
    const [closedDate] = useState(new Date("January 30, 2025"));
    const [reopenDate] = useState(new Date("February 4, 2025"));
    const [isClosed, setIsClosed] = useState(false);
    const [isVisible, setIsVisible] = useState(true); // Controls visibility

    useEffect(() => {
        // Check if the current date falls within the closure period
        const today = new Date();
        setIsClosed(today >= closedDate && today < reopenDate);
    }, [closedDate, reopenDate]);

    if (!isClosed || !isVisible) {
        // Do not render the notification if the office is not closed or user dismissed it
        return null;
    }

    return (
        <div className="office-status">
            <div className="office-status-container">
                <div className="office-status-message">
                    <h4>Office Status</h4>
                    <p>
                        Our office is currently <span className="status-highlight">closed</span>.
                        <br />
                        We will reopen on{" "}
                        <span className="status-date">{reopenDate.toDateString()}</span>.
                    </p>
                </div>
                <button
                    className="office-status-close"
                    onClick={() => setIsVisible(false)}
                    aria-label="Close notification"
                >
                    &times;
                </button>
            </div>
        </div>
    );
};

export default OfficeStatus;
